export const blogData = [
    {
        id: 1,
        title: 'SCRAPING BILLBOARD AND IMDB USING PYTHON SELENIUM',
        description: 'Here we will use Selenium to navigate between web pages, and try to scrape data from them.',
        date: 'Aug 7, 2020',
        image: 'images/1 (3).jpg',
        url: 'http://superblank.me/'
    },
    {
        id: 2,
        title: 'CHECK WEATHER FROM TERMINAL USING WTTR.IN',
        description: 'wttr.in is a console-oriented weather forecast service that supports various information representation methods like ANSI-sequences for console HTTP clients, HTML, or PNG.',
        date: 'Aug 11, 2020',
        image: 'images/1 (2).jpg',
        url: 'http://superblank.me/'
    },
    {
        id: 3,
        title: 'BEING ANONYMOUS: A BEGINNERS GUIDE',
        description: 'Here are some basic ways to lay off the Radar',
        date: 'Aug 14, 2020',
        image: 'images/1 (1).jpg',
        url: 'http://superblank.me/'
    },
   
]



