import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'



export const projectsData = [
    {
        id: 1,
        projectName: 'Bell State Measurements ',
        projectDesc: 'BSM are two-photon measurements that lie at the heart of most protocols in Quantum Information Theory, they are also helpful in deepening our understanding of Quantum Theory itself. In this master’s thesis project, a detailed theoretical and experimental study of Bell State Measurements was undertaken',
        tags: ['React', 'CSS', 'Material Ui'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: one
    },
    {
        id: 2,
        projectName: 'Self-certifying QRNG',
        projectDesc: 'Quantum Random Number Generators use random collapses of a wave-function to generate random numbers. In this project, I proposed an interferometric design for a QRNG that can also violate a Bell inequality, and hence, certify that the random numbers generated are quantum random.  ',
        tags: ['Flutter'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: two
    },
    {
        id: 3,
        projectName: 'Entangled Photons Source',
        projectDesc: 'In this experimental project, I created a source of entangled photons which were entangled in polarization degree of freedom. I incident a high intensity 405nm wavelength laser beam on a non-linear BBO crystal to obtain down-converted entangled photons. Their wavelength was 810nm. ',
        tags: ['Django', 'Tailwind CSS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: three
    },
    {
        id: 4,
        projectName: 'Quantum State Tomography',
        projectDesc: 'QST is a methodology of experimental reconstruction of an unknown quantum state by projecting an ensemble of identically prepared states onto different basis choices. In this project, I wrote python codes to reconstruct unknown density matrix from the experimentally obtained projection data.',
        tags: ['Django', 'Tailwind CSS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: four
    },
    {
        id: 5,
        projectName: 'Quantum Information and Noise',
        projectDesc: 'This was a theory project focused on understanding the nature of quantum information. In this project, I coverd what is quantum information, how it differs from the conventional classical information, how is it dealt with, different kind of experimental challenges faced while dealing with it and its potential applications.',
        tags: ['Django', 'CSS', 'Material Ui'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: five
    },
    {
        id: 6,
        projectName: 'Investigating Why Photons Cannot be Sharply Localized',
        projectDesc: 'In this project, I explored why localization of photons is a difficult task. The problem comes from the fact that localization of a photon requires localization of both electric and magnetic components of the photon. The electric and magnetic components are conjugate to each other and it is possible to sharply localize only one of the variables. ',
        tags: ['Flutter', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },
    {
        id: 7,
        projectName: 'Why Delayed Choice Experiments Do Not Imply Retro-Causality',
        projectDesc: 'In this project, I learned about the Delayed Choice Experiment and the seemingly retro-causal effects demonstrated by it. I learned how this retro-causality is just a misinterpretation of the result and how we need be careful while interpreting QM. This has helped me in building a solid foundation of QM and in avoiding common traps of misconceptions.',
        tags: ['React Native', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: seven
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/