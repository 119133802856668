import React from 'react'

import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <h2>Reality is just a set of beliefs, I believe. </h2>
      
      <div className="footer" >
            <p >
                Made with ❤ by Jane Doe
                
            </p>
        </div>
    </div>
  );
}

export default Footer;
