export const educationData = [
    {
        id: 1,
        institution: 'Indian Institute of Science Education and Research (IISER) Pune',
        course: 'BS-MS DUAL DEGREE IN BASIC SCIENCE',
        startYear: '2017',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'Dharampeth Science College, Nagpur',
        course: 'HIGHER SECONDARY EDUCATION',
        startYear: '2014',
        endYear: '2016'
    },
    {
        id: 3,
        institution: 'Bhonsala Military School, Nagpur',
        course: 'SECONDARY SCHOOL EDUCATION',
        startYear: '2008',
        endYear: '2014'
    },
]